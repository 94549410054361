import { Box, Button, Center, Drawer, Loader, Popover, Stack, TextInput, TextInputProps, useMantineTheme } from "@mantine/core"
import { ChangeEvent, FocusEvent, KeyboardEvent, useCallback, useContext, useEffect, useRef, useState, useTransition } from "react"

import { useAuth } from "@clerk/nextjs"
import { GetToken } from "@clerk/types"
import { useDisclosure, useId, useMediaQuery } from "@mantine/hooks"
import { UserContext } from "@soar/frontend/contexts"
import { trpc } from "@soar/frontend/trpc-client"
import { Airport, AirportResult, AirportSchema } from "@soar/shared/types"
import {
  convertAirportToAirportResult,
  getTypesenseCollectionName,
  getTypesenseSearchClient,
  supabase,
  typesenseSearchClient,
} from "@soar/shared/utils"
import { useQuery } from "@tanstack/react-query"
import { AirportInputDrawer } from "../airportInputDrawer"
import { AirportInputResultsDisplay, AirportResultDisplay, NavResultDisplay } from "../results"
import { NavResult } from "../types"
import { AirportInputClearButton } from "./clearButton"

async function getSearchResults(query: string, getToken: GetToken, includeNav = false) {
  const templateName = `supabase-${process.env.NEXT_PUBLIC_ENV ?? process.env.NODE_ENV}`
  const token = await getToken({ template: templateName })
  const { data, error } = await supabase.functions.invoke("airport-search", {
    body: {
      query,
      limit: 20,
      includeNav,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (error) {
    throw error
  }
  return data
}

async function getSearchResultsTypesense(query: string, apiKey: string) {
  const client = getTypesenseSearchClient(apiKey)
  const results = await client.collections(getTypesenseCollectionName("airports")).documents().search(
    {
      q: query,
      query_by: "icaoId, faaAirportId, name, city, stateCode, countryCode",
      per_page: 15,
    },
    {},
  )
  const parsedResults = (results.hits ?? []).map((hit) => {
    const airport = hit.document as Airport
    return convertAirportToAirportResult(airport)
  })
  // console.log("parsedResults: ", parsedResults)
  return { results: parsedResults, navResults: [] }
}

export function AirportInput({
  textInputProps = {},
  drawertextInputProps = {},
  onSelect = () => {},
  value,
  initialValue,
  includeNav = false,
  selectOnBlur = true,
  placeholder,
  disabledEditFields,
}: {
  value?: string
  initialValue?: string
  textInputProps?: Partial<TextInputProps>
  drawertextInputProps?: Partial<TextInputProps>
  onSelect?: (airport: AirportResult | undefined) => void
  includeNav?: boolean
  selectOnBlur?: boolean
  placeholder?: string
  disabledEditFields?: boolean
}) {
  const [_isPending, startTransition] = useTransition()
  const [searchValue, setSearchValue] = useState("")
  const [displayValue, setDisplayValue] = useState("")
  const [popoverState, popoverHandlers] = useDisclosure(false)
  const [drawerState, drawerHandlers] = useDisclosure(false)
  const [selectedIndex, setSelectedIndex] = useState<number>()
  const [selectedAirport, setSelectedAirport] = useState<AirportResult>()
  const { searchKey } = useContext(UserContext)
  const drawerInputRef = useRef<HTMLInputElement>(null)

  const theme = useMantineTheme()
  const shouldUseDrawer = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  useEffect(() => {
    if (value != null) {
      setSearchValue(value)
      setDisplayValue(value)
    }
  }, [value])
  const searchValueIsValid = searchValue.trim().length > 0

  const { getToken } = useAuth()
  const { data, isLoading } = useQuery({
    queryKey: ["airport-search", searchValue],
    queryFn: () => getSearchResultsTypesense(searchValue, searchKey?.key ?? ""),
    enabled: searchValueIsValid,
    retry: true,
    onError(e) {
      console.error("Search error: ", e)
    },
  })

  const { data: dataInitial, status: statusInitial } = trpc.airport.getAirportByIdentifier.useQuery(
    { identifier: initialValue! },
    {
      enabled: initialValue != null && initialValue.trim().length > 0,
    },
  )

  useEffect(() => {
    if (dataInitial != null) {
      const airport = AirportSchema.parse(dataInitial)
      const airportResult = convertAirportToAirportResult(airport)
      handleSelect(airportResult)
    }
  }, [dataInitial])

  const airportResults: AirportResult[] = data?.results ?? []
  const navResults: NavResult[] = data?.navResults ?? []
  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDisplayValue(e.target.value)
    setSelectedIndex(undefined)
    if (e.target.value.length === 0) {
      popoverHandlers.close()
    }
    startTransition(() => {
      setSearchValue(e.target.value)
      openIfNeeded(e.target.value)
    })
  }
  const onChange = (value: string) => {
    setDisplayValue(value)
    startTransition(() => {
      setSearchValue(value)
    })
  }

  const handleSelect = (airport: AirportResult | undefined) => {
    if (airport != null) {
      const identifier = airport.icao_id ?? airport.faa_airport_id ?? ""

      // select element
      setDisplayValue(identifier)
      popoverHandlers.close()
      setSelectedAirport(airport)
      onSelect(airport)
      startTransition(() => {
        setSearchValue(identifier)
      })
    } else {
      popoverHandlers.close()
      onSelect(undefined)
    }
  }

  const onBlur = useCallback(() => {
    if (shouldUseDrawer) {
    } else {
      const airportResults: AirportResult[] = data?.results ?? []
      popoverHandlers.close()

      if (airportResults.length > 0 && selectOnBlur) {
        const firstResult = airportResults.at(0)!
        const capsSearchValue = searchValue.toUpperCase()
        if (firstResult.icao_id === capsSearchValue || firstResult.faa_airport_id === capsSearchValue) {
          handleSelect(firstResult)
        } else if (selectedAirport == null) {
          handleSelect(undefined)
        } else {
        }
      }
    }
  }, [data, searchValue, selectOnBlur, shouldUseDrawer, selectedAirport])

  const openIfNeeded = (inputValue?: string) => {
    const value = inputValue ?? searchValue
    if (value.length > 0) {
      popoverHandlers.open()
    } else {
      popoverHandlers.close()
    }
  }
  const onFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (shouldUseDrawer) {
        drawerHandlers.open()

        // The following fakeInput stuff is to get around iOS focusing restrictions
        const fakeInput = document.createElement("input")
        fakeInput.setAttribute("type", "text")
        fakeInput.style.position = "absolute"
        fakeInput.style.opacity = "0"
        fakeInput.style.height = "0"
        fakeInput.style.fontSize = "16px"
        document.body.prepend(fakeInput)
        fakeInput.focus()

        setTimeout(() => {
          drawerInputRef.current?.focus()
          fakeInput.remove()
        }, 200)
      } else {
        openIfNeeded()
      }
    },
    [shouldUseDrawer],
  )

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowUp": {
        e.preventDefault()
        if (popoverState && airportResults.length > 0 && (selectedIndex == null || selectedIndex > 0)) {
          setSelectedIndex((selectedIndex ?? -1) - 1)
        }
        // isColumn ? handlePrevious() : handleNext();
        break
      }

      case "ArrowDown": {
        e.preventDefault()
        if (popoverState && airportResults.length > 0 && (selectedIndex == null || selectedIndex < airportResults.length - 1)) {
          setSelectedIndex((selectedIndex ?? -1) + 1)
        }
        // isColumn ? handleNext() : handlePrevious();
        break
      }

      case "Enter": {
        if (popoverState && selectedIndex != null) {
          e.preventDefault()
          const airport = airportResults[selectedIndex]
          handleSelect(airport)
        }
        break
      }

      case "Escape": {
        if (popoverState) {
          e.preventDefault()
          popoverHandlers.close()
        }
        break
      }
    }
  }
  const handleClear = () => {
    setDisplayValue("")
    setSearchValue("")
    handleSelect(undefined)
  }

  return (
    <>
      <Popover
        opened={popoverState}
        onClose={() => {
          // onBlur()
        }}
        transitionProps={{ duration: 150, transition: "fade" }}
        radius="lg"
        shadow="lg"
        middlewares={{ flip: false, shift: false, inline: false }}
        styles={(theme) => ({
          dropdown: {
            padding: "0",
            marginTop: "-8px",
            overflow: "hidden",
            height: "40vh",
            maxHeight: "40vh",
          },
        })}
        width="target"
        position="bottom"
        disabled={shouldUseDrawer || disabledEditFields}
      >
        <Popover.Target>
          <TextInput
            placeholder={placeholder}
            {...textInputProps}
            value={displayValue}
            onChange={onValueChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            rightSection={searchValueIsValid ? <AirportInputClearButton onClick={handleClear} /> : undefined}
            disabled={disabledEditFields}
          />
        </Popover.Target>
        <Popover.Dropdown>
          {isLoading ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <AirportInputResultsDisplay
              airportResults={airportResults}
              navResults={navResults}
              onSelect={handleSelect}
              showFavoriteAirports={displayValue == null || displayValue.length === 0}
              selectedIndex={selectedIndex}
            />
          )}
        </Popover.Dropdown>
      </Popover>
      <AirportInputDrawer
        ref={drawerInputRef}
        value={displayValue}
        onClose={drawerHandlers.close}
        opened={drawerState}
        onChange={onChange}
        onSelect={onSelect}
        airportResults={airportResults}
        navResults={navResults}
        placeholder={placeholder}
        textInputProps={drawertextInputProps}
      />
    </>
  )
}
