import { ActionIcon, Avatar, Box, Flex, Header as MHeader, MediaQuery, Menu, Text, UnstyledButton, createStyles } from "@mantine/core"
import { IconClock, IconCreditCard, IconLogout, IconMenu2, IconSettings, IconUser } from "@tabler/icons-react"
import { ReactNode } from "react"
import { CustomLink } from "../customLink"

interface IHeaderProps {
  isAuthenticated?: boolean
  onLogout?: () => void
  avatarUrl?: string
  avatarPlaceholder?: string
  toggleNavbarExpanded?: () => void
  children?: ReactNode
}

function noop() {
  console.warn("noop called")
}

export const headerButtonPortalId = "header-button-portal" as const
export const headerLeftPortalId = "header-left-portal" as const
const useHeaderStyles = createStyles((theme) => ({
  onlyTablet: {
    display: "inherit",
  },
}))

export function Header({
  isAuthenticated = false,
  avatarUrl,
  avatarPlaceholder,
  onLogout = noop,
  toggleNavbarExpanded = noop,
  children,
}: IHeaderProps) {
  return (
    <MHeader height={{ base: 60, md: 60 }} py="xs" px="sm" bg="#FBFBFA" style={{ borderBottom: "none" }}>
      <Flex align="center" justify="space-between">
        <Flex align="center">
          {isAuthenticated && (
            <>
              <MediaQuery largerThan="sm" smallerThan="md" styles={{ display: "block" }}>
                <ActionIcon onClick={toggleNavbarExpanded} mr="sm" title="hamburger menu" color="gray.8" variant="subtle" display="none">
                  <IconMenu2 height={20} />
                </ActionIcon>
              </MediaQuery>
              <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                <Box display="none">
                  <img src="/AirTera_Logo_Icon_Stratus.svg" alt="soar icon" width="24" height="24" />
                </Box>
              </MediaQuery>
            </>
          )}

          <div id={headerLeftPortalId} />
        </Flex>

        <Flex align="center" gap="md">
          <div id={headerButtonPortalId} />
          {children}
          {isAuthenticated && (
            <Menu
              styles={(theme) => ({
                dropdown: {
                  marginRight: theme.spacing.sm,
                  marginLeft: theme.spacing.sm,
                },
              })}
              offset={{
                mainAxis: 8,
                crossAxis: -10,
              }}
              position="bottom-end"
              shadow="lg"
              radius="md"
              transitionProps={{ duration: 150, transition: "fade" }}
              withinPortal
            >
              <Menu.Target>
                <UnstyledButton>
                  <Avatar
                    src={avatarUrl}
                    size={36}
                    mt={2}
                    mr={3}
                    radius="xl"
                    color="blue.5"
                    styles={{
                      root: {
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                  >
                    {avatarPlaceholder}
                  </Avatar>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                <CustomLink href="/profile" passHref legacyBehavior>
                  <Menu.Item icon={<IconUser size={14} />} component="a">
                    Profile
                  </Menu.Item>
                </CustomLink>
                {/*
                <CustomLink href="/preferences" passHref legacyBehavior>
                  <Menu.Item icon={<IconSettings size={14} />} component="a">
                    Preferences
                  </Menu.Item>
                </CustomLink>
                */}
                <Menu.Item icon={<IconLogout size={14} />} onClick={onLogout}>
                  Logout
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </Flex>
      </Flex>
    </MHeader>
  )
}
