import { Box, Button, Flex, FlexProps, MantineSize, Menu, Text, UnstyledButton } from "@mantine/core"
import { SafetyReportStatus, SafetyReportStatusEnum } from "@soar/shared/types"
import { SafetyReportStatusConfig } from "@soar/shared/utils"
import { ReactElement, useMemo, useState } from "react"
import { MaterialSymbol } from "../icons"

const allStatuses = [
  SafetyReportStatusEnum.enum.created,
  SafetyReportStatusEnum.enum.underReview,
  SafetyReportStatusEnum.enum.inProgress,
  SafetyReportStatusEnum.enum.resolved,
]

const SafetyReportStatusIcons: Record<SafetyReportStatus, ReactElement> = {
  [SafetyReportStatusEnum.enum.created]: <MaterialSymbol name="circle" />,
  [SafetyReportStatusEnum.enum.underReview]: <MaterialSymbol name="search_insights" fill />,
  [SafetyReportStatusEnum.enum.inProgress]: <MaterialSymbol name="progress_activity" />,
  [SafetyReportStatusEnum.enum.resolved]: <MaterialSymbol name="task_alt" />,
}

export function SafetyReportStatusIcon({
  status,
  size,
}: {
  status: SafetyReportStatus
  size?: MantineSize
}) {
  const currentConfig = SafetyReportStatusConfig[status]
  return (
    <Flex align="center" fz={size} c={currentConfig.color}>
      {SafetyReportStatusIcons[status]}
    </Flex>
  )
}

export function SafetyReportStatusDisplay({
  status,
  justify,
  size,
}: {
  status: SafetyReportStatus
  justify?: FlexProps["justify"]
  size?: MantineSize
}) {
  const currentConfig = SafetyReportStatusConfig[status]
  return (
    <Flex fz="xs" display="flex" align="center" gap={5} justify={justify}>
      <Flex align="center" c={currentConfig.color}>
        {SafetyReportStatusIcons[status]}
      </Flex>
      <Text truncate>{currentConfig.label}</Text>
    </Flex>
  )
}

export function SafetyReportStatusMenu({
  status,
  onChange = () => {},
}: {
  status: SafetyReportStatus
  onChange?: (status: SafetyReportStatus) => void
}) {
  const { otherStatuses, currentConfig } = useMemo(() => {
    const otherStatuses = allStatuses.filter((statusFilter) => statusFilter !== status)
    const currentConfig = SafetyReportStatusConfig[status]

    return {
      otherStatuses,
      currentConfig,
    }
  }, [status])
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Menu onChange={setIsOpen}>
      <Menu.Target>
        <Button
          variant="outline"
          fz="xs"
          color="gray.4"
          pl={10}
          pr={5}
          h={25}
          radius="md"
          rightIcon={
            <Flex c="dark.4" fz="sm" align="center">
              <MaterialSymbol name={isOpen ? "chevron_right" : "keyboard_arrow_down"} />
            </Flex>
          }
        >
          <Box fw={600} c="black">
            <Text truncate>{currentConfig.label}</Text>
          </Box>
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {otherStatuses.map((statusInLoop) => {
          return (
            <Menu.Item key={statusInLoop} onClick={() => onChange(statusInLoop)}>
              <Box fw={500}>
                <SafetyReportStatusDisplay status={statusInLoop} />
              </Box>
            </Menu.Item>
          )
        })}
      </Menu.Dropdown>
    </Menu>
  )
}

export function SafetyReportStatusBadge() {}
