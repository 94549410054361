import { AircraftStatus, AircraftStatusEnum } from "@soar/shared/types"

export type AircraftStatusConfigDetails = {
  label: string
  colorType: "mantine" | "custom"
  color: string
}

export const aircraftStatusConfig: Record<AircraftStatusEnum, AircraftStatusConfigDetails> = {
  [AircraftStatus.Enum.Active]: {
    label: "Active",
    colorType: "mantine",
    color: "green.8",
  },
  [AircraftStatus.Enum.Inactive]: {
    label: "Inactive",
    colorType: "mantine",
    color: "brandBlue.8",
  },
}
