import { Chip, ChipGroupProps, Flex, createStyles } from "@mantine/core"
import { DashboardTimespan, DashboardTimespanEnum } from "@soar/shared/types"
import { useCustomChipStyles } from "../customChip"
import { BUTTON_COLORS } from "../tokens"

export function TimespanChipGroup({
  value,
  onChange,
  showAll = true,
}: {
  onChange: (timespan: DashboardTimespan) => void
  value: DashboardTimespan | undefined
  showAll?: boolean
}) {
  const { classes } = useCustomChipStyles()
  return (
    <Chip.Group
      value={value}
      onChange={(value) => {
        if (!Array.isArray(value) && DashboardTimespanEnum.safeParse(value).success) {
          onChange(value as DashboardTimespan)
        }
      }}
    >
      <Flex gap="xs">
        <Chip variant="filled" color="brandBlue.8" fz={2} classNames={classes} value="today">
          Today
        </Chip>
        <Chip variant="filled" color="brandBlue.8" classNames={classes} value="week_1">
          1W
        </Chip>
        <Chip variant="filled" color="brandBlue.8" classNames={classes} value="week_4">
          4W
        </Chip>
        <Chip variant="filled" color="brandBlue.8" classNames={classes} value="mtd">
          MTD
        </Chip>
        <Chip variant="filled" color="brandBlue.8" classNames={classes} value="ytd">
          YTD
        </Chip>
        {showAll && (
          <Chip variant="filled" color="brandBlue.8" classNames={classes} value="all">
            All
          </Chip>
        )}
      </Flex>
    </Chip.Group>
  )
}
