import { SubmittedActivityNoteType } from "@soar/shared/types"

export function getSafetyReportNoteAndMentions(richTextJSON: any, values: string) {
  const richTextString = JSON.stringify(richTextJSON, null, 2)
  const plainTextForm = values
  const mentions = getMentions(richTextString)
  if (richTextString != null) {
    const submittedNote: SubmittedActivityNoteType = {
      type: "rich_text",
      source: "tiptap",
      plainText: plainTextForm,
      richText: {
        text: plainTextForm,
        mentions: mentions,
      },
    }
    return submittedNote
  }
  return null
}

export function getMentions(richTextString: string) {
  const doc = JSON.parse(richTextString)
  const mentions: { id: string; label: string }[] = []

  function traverse(node: { type: string; attrs: { id: string; label: string }; content: any[] }) {
    if (node.type === "mention" && node.attrs) {
      mentions.push({
        id: node.attrs.id,
        label: node.attrs.label,
      })
    }

    if (node.content && Array.isArray(node.content)) {
      node.content.forEach((child) => traverse(child))
    }
  }

  traverse(doc)
  return mentions
}
