import { ActionIcon, Box, Flex } from "@mantine/core"
import { useForm } from "@mantine/form"
import { RichTextEditor } from "@mantine/tiptap"
import { UserContext, organizationsForUserAtom } from "@soar/frontend/contexts"
import { RegisteredUser } from "@soar/shared/types"
import { getSafetyReportNoteAndMentions, getTypesenseCollectionName, getTypesenseSearchClient } from "@soar/shared/utils"
import { IconCheck, IconX } from "@tabler/icons-react"
import Document from "@tiptap/extension-document"
import Mention from "@tiptap/extension-mention"
import Paragraph from "@tiptap/extension-paragraph"
import Placeholder from "@tiptap/extension-placeholder"
import Text from "@tiptap/extension-text"
import { EditorContent, JSONContent, useEditor } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import { useAtomValue } from "jotai"
import { useContext, useEffect } from "react"
import tippy, { Instance } from "tippy.js"
import { PAGE_BACKGROUND_COLOR } from "../tokens"
import { useCustomRichTextEditorStyles } from "./styles"

async function getSearchResultsTypesense(query: string, apiKey: string, organizations: string[]) {
  const client = getTypesenseSearchClient(apiKey)
  const results = await client.collections(getTypesenseCollectionName("users")).documents().search(
    {
      q: query,
      query_by: "firstName, lastName, email",
      per_page: 15,
    },
    {},
  )
  const parsedResults = (results.hits ?? []).map((hit) => {
    const user = hit.document as RegisteredUser
    return {
      ...user,
      id: user.id,
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    }
  })
  return parsedResults
}

// Mention extension with dynamic Typesense support
export const MentionsExtension = (apiKey: string, organizations: string[], dropdownMenuClass: string) =>
  Mention.configure({
    HTMLAttributes: {
      class: "mention",
    },
    suggestion: {
      char: "@",
      items: async ({ query }) => {
        const results = await getSearchResultsTypesense(query, apiKey, organizations)
        return results.map((user) => ({
          id: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }))
      },
      render: () => {
        let popup: Instance | null = null
        let component: HTMLDivElement

        return {
          onStart: (props) => {
            component = document.createElement("div")
            component.className = dropdownMenuClass

            props.items.forEach((item) => {
              const button = document.createElement("button")
              button.textContent = item.label
              button.addEventListener("click", () => props.command(item))
              component.appendChild(button)
            })

            const rect = props.clientRect?.()
            if (rect) {
              popup = tippy(document.body, {
                getReferenceClientRect: () => rect,
                appendTo: () => document.body,
                content: component,
                showOnCreate: true,
                interactive: true,
                trigger: "manual",
                placement: "bottom-start",
              })
            }
          },
          onUpdate(props) {
            if (!popup || !component) return

            // Clear old options
            component.innerHTML = ""

            // Re-add new items with styling
            props.items.forEach((item) => {
              const button = document.createElement("button")
              button.textContent = item.label
              button.addEventListener("click", () => props.command(item))
              component.appendChild(button)
            })

            const rect = props.clientRect?.()
            if (rect) {
              popup.setProps({
                getReferenceClientRect: () => rect,
              })
            }
          },
          onExit() {
            if (popup) {
              popup.destroy()
              popup = null
            }
          },
        }
      },
    },
  })

export function CustomRichTextFieldWithMenu({
  onSubmitClick = () => {},
  onCancel,
  placeholder,
}: {
  onSubmitClick?: (value: JSONContent) => void
  onCancel?: () => void
  placeholder?: string
}) {
  const { cx, classes } = useCustomRichTextEditorStyles()

  const form = useForm({
    initialValues: {
      content: "",
    },
  })

  const editor = useEditor({
    extensions: [
      StarterKit,
      Document,
      Paragraph,
      Text,
      Placeholder.configure({
        placeholder: placeholder,
      }),
    ],
    content: "",
  })

  useEffect(() => {
    if (!editor) return
    editor.on("update", () => {
      form.setFieldValue("content", editor.getText())
    })
  }, [editor])

  const handleCancel = () => {
    editor?.commands.clearContent()
    form.setFieldValue("content", "")
    if (onCancel) {
      onCancel()
    }
  }

  const handleSubmit = (values: typeof form.values) => {
    const richTextJSON = editor?.getJSON()
    if (richTextJSON != null) {
      onSubmitClick(richTextJSON)
    }
    editor?.commands.clearContent()
    form.setFieldValue("content", "")
  }

  return (
    <Box className={cx(classes.customEditor)}>
      <RichTextEditor editor={editor}>
        <RichTextEditor.Toolbar bg={PAGE_BACKGROUND_COLOR} sticky stickyOffset={60}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
            <RichTextEditor.Code />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Subscript />
            <RichTextEditor.Superscript />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content bg={PAGE_BACKGROUND_COLOR} />
        <Flex justify="flex-end" gap="md" mt="sm" pb="xs" pr="xs">
          {onCancel != null && (
            <ActionIcon variant="filled" radius="xl" color="gray" onClick={handleCancel}>
              <IconX height={16} width={16} />
            </ActionIcon>
          )}
          <ActionIcon
            variant="filled"
            radius="xl"
            color="brandBlue"
            onClick={(e) => {
              e.preventDefault()
              form.onSubmit(handleSubmit)()
            }}
          >
            <IconCheck height={16} width={16} />
          </ActionIcon>
        </Flex>
      </RichTextEditor>
    </Box>
  )
}

export function CustomRichTextFieldWithoutMenu({
  onSubmitClick = () => {},
  onCancel,
  placeholder,
}: {
  onSubmitClick?: (value: JSONContent) => void
  onCancel?: () => void
  placeholder?: string
}) {
  const organizations = useAtomValue(organizationsForUserAtom)
  const { searchKey } = useContext(UserContext)
  const { cx, classes } = useCustomRichTextEditorStyles()

  const form = useForm({
    initialValues: {
      content: "",
    },
  })

  const editor = useEditor({
    extensions: [
      StarterKit,
      Document,
      Paragraph,
      Text,
      Placeholder.configure({
        placeholder: placeholder,
      }),
      MentionsExtension(searchKey?.key ?? "", organizations?.map((org) => org.id) ?? [], classes.dropdownMenu),
    ],
    content: "",
  })

  useEffect(() => {
    if (!editor) return
    editor.on("update", () => {
      form.setFieldValue("content", editor.getText())
    })
  }, [editor])

  const handleCancel = () => {
    editor?.commands.clearContent()
    form.setFieldValue("content", "")
    if (onCancel) {
      onCancel()
    }
  }

  const handleSubmit = (values: typeof form.values) => {
    const richTextJSON = editor?.getJSON()
    const submittedNote = getSafetyReportNoteAndMentions(richTextJSON, values.content)
    if (submittedNote != null) {
      onSubmitClick(submittedNote)
    }
    editor?.commands.clearContent()
    form.setFieldValue("content", "")
  }

  return (
    <Box className={cx(classes.customEditor)}>
      <RichTextEditor editor={editor} variant="unstyled">
        <RichTextEditor.Content bg={PAGE_BACKGROUND_COLOR} />
        <Flex justify="flex-end" gap="md" mt="sm" pb="xs" pr="xs">
          {onCancel != null && (
            <ActionIcon variant="filled" radius="xl" color="gray" onClick={handleCancel}>
              <IconX height={16} width={16} />
            </ActionIcon>
          )}
          <ActionIcon
            variant="filled"
            radius="xl"
            color="brandBlue"
            onClick={(e) => {
              e.preventDefault()
              form.onSubmit(handleSubmit)()
            }}
          >
            <IconCheck height={16} width={16} />
          </ActionIcon>
        </Flex>
      </RichTextEditor>
    </Box>
  )
}
