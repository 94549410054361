import { Group, Skeleton, Text, useMantineTheme } from "@mantine/core"
import { AssessmentThreatLevel, Risk, SafetyAssessmentGroup, SafetyBriefMode } from "@soar/shared/types"
import { AssessmentThreatColors, getAssessmentThreatScoreLevel } from "@soar/shared/utils"
import { RiskBadge } from "../weather"
import { useMitigations } from "./mitigationsAtom"

export function OverviewSafetyRisksGroupSkeleton() {
  return (
    <>
      <Skeleton height={8} radius="xl" mt="sm" />
      <Skeleton height={8} radius="xl" mt="sm" />
    </>
  )
}

export function OverviewSafetyRisksGroup({
  risks,
}: {
  risks?: Risk[]
}) {
  if (risks == null) {
    return <OverviewSafetyRisksGroupSkeleton />
  }
  return (
    <Group>
      {risks.map((risk, index) => (
        <RiskBadge key={index} label={risk.label} severity={risk.severity} />
      ))}
    </Group>
  )
}

export function OverviewSafetyAssessmentGroup({
  assessmentThreats,
  color = "#333",
}: {
  assessmentThreats?: SafetyAssessmentGroup
  color?: string
}) {
  const { mitigations } = useMitigations()
  const theme = useMantineTheme()
  if (assessmentThreats == null) {
    return <OverviewSafetyRisksGroupSkeleton />
  }
  return (
    <Group>
      {assessmentThreats.threats.map((threat, index) => {
        const isMitigated = mitigations.some((mitigation) => mitigation.threatId === threat.id)
        const severityLevel = getAssessmentThreatScoreLevel(threat.severity)
        const colorToUse = theme.colors.grape[6] //AssessmentThreatColors[severityLevel]

        return (
          <RiskBadge
            key={index}
            label={`${threat.label} +${threat.severity}`}
            severity={threat.severity}
            color={"spotlight.7"}
            showIcon={false}
            variant={isMitigated ? "filled" : "outline"}
            textColor={isMitigated ? "#00000066" : undefined}
          />
        )
      })}
    </Group>
  )
}

export function OverviewThreatCount({
  isLoading,
  safetyBriefMode,
  assessmentThreats,
  risks,
}: {
  isLoading: boolean
  safetyBriefMode: SafetyBriefMode
  assessmentThreats?: SafetyAssessmentGroup
  risks?: Risk[]
}) {
  const safetyAssessmentLevel = assessmentThreats != null ? getAssessmentThreatScoreLevel(assessmentThreats?.score) : undefined
  const safetyAssessmentColor = safetyAssessmentLevel != null ? AssessmentThreatColors[safetyAssessmentLevel] : undefined

  if (isLoading) {
    return (
      <>
        Risks (
        <Skeleton height={13} width={13} display="inline-block" mx="2px" />)
      </>
    )
  } else if (safetyBriefMode === "data") {
    return <>Risks ({risks?.length})</>
  } else {
    return <>Score ({assessmentThreats?.score})</>
  }
}
