import {
  Permission,
  PermissionConditions,
  PermissionName,
  PermissionNameEnum,
  PermissionScope,
  PermissionsScopeEnum,
  Reservation,
  ReservationUser,
  User,
  UserRole,
} from "@soar/shared/types"

function addPermissionToMap(map: Map<PermissionName, PermissionConditions>, permission: PermissionName, scopes?: Permission["scopes"]) {
  map.set(permission, { scopes })
}

export type ScopeDependencies = {
  reservation?: Reservation
  userRoleMap?: Record<string, UserRole[]>
}

export function generatePermissions(user: User, roles: UserRole[]) {
  const permissionMap = new Map<PermissionName, PermissionConditions>()

  if (roles.includes(UserRole.enum.PILOT) || roles.includes(UserRole.enum.MECHANIC)) {
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CANCEL_RESERVATION_BLOCK, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CANCEL_RESERVATION_FLIGHT, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CANCEL_RESERVATION_GROUND, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_RESERVATION_BLOCK, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_RESERVATION_FLIGHT, [
      PermissionsScopeEnum.enum.IS_ON_RESERVATION,
      PermissionsScopeEnum.enum.OTHER_USERS_ARE_STAFF_PILOTS,
    ])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_RESERVATION_GROUND, [
      PermissionsScopeEnum.enum.IS_ON_RESERVATION,
      PermissionsScopeEnum.enum.OTHER_USERS_ARE_STAFF_PILOTS,
    ])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_RESERVATION_BLOCK, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_RESERVATION_FLIGHT, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_RESERVATION_GROUND, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.SIGN_RESERVATION_IN, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.SIGN_RESERVATION_OUT, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_AIRCRAFT_LIST)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_AIRCRAFT_PROFILES)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_PEOPLE_LIST)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_PEOPLE_PROFILES)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_PEOPLE_PROFILES)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_FLIGHT_LEG_COMMENT)
  }

  /*
  if (roles.includes(UserRole.enum.STAFF_PILOT)) {
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CANCEL_RESERVATION_BLOCK, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CANCEL_RESERVATION_FLIGHT, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CANCEL_RESERVATION_GROUND, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_RESERVATION_BLOCK, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_RESERVATION_FLIGHT, [
      PermissionsScopeEnum.enum.IS_ON_RESERVATION,
      PermissionsScopeEnum.enum.OTHER_USERS_ARE_PILOTS,
    ])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_RESERVATION_GROUND, [
      PermissionsScopeEnum.enum.IS_ON_RESERVATION,
      PermissionsScopeEnum.enum.OTHER_USERS_ARE_PILOTS,
    ])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_RESERVATION_BLOCK, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_RESERVATION_FLIGHT, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_RESERVATION_GROUND, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.SIGN_RESERVATION_IN, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.SIGN_RESERVATION_OUT, [PermissionsScopeEnum.enum.IS_ON_RESERVATION])
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_AIRCRAFT_LIST)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_AIRCRAFT_PROFILES)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_PEOPLE_LIST)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_PEOPLE_PROFILES)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.APPROVE_FLIGHT_LEG)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_FLIGHT_LEG_COMMENT)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_SAFETY_CONTROLS)
  }
  */

  if (roles.includes(UserRole.enum.STAFF_PILOT)) {
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CANCEL_RESERVATION_BLOCK)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CANCEL_RESERVATION_FLIGHT)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CANCEL_RESERVATION_GROUND)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CANCEL_RESERVATION_MAINTENANCE)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_RESERVATION_BLOCK)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_RESERVATION_FLIGHT)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_RESERVATION_GROUND)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_RESERVATION_MAINTENANCE)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_RESERVATION_BLOCK)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_RESERVATION_FLIGHT)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_RESERVATION_GROUND)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_RESERVATION_MAINTENANCE)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.SIGN_RESERVATION_IN)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.SIGN_RESERVATION_OUT)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_AIRCRAFT_LIST)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_AIRCRAFT_PROFILES)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_PEOPLE_LIST)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_PEOPLE_PROFILES)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.APPROVE_FLIGHT_LEG)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_SAFETY_FEEDBACK)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_ORGANIZATION_DASHBOARD)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_SAFETY_CONTROLS)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_SAFETY_CONTROLS)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_SAFETY_CONTROLS)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_FLIGHT_LEG_COMMENT)
  }

  if (roles.includes(UserRole.enum.ADMIN) || roles.includes(UserRole.enum.SUPERADMIN)) {
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.ADD_AIRCRAFT_PROFILES)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.ADD_PEOPLE_PROFILES)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CANCEL_RESERVATION_BLOCK)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CANCEL_RESERVATION_FLIGHT)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CANCEL_RESERVATION_GROUND)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CANCEL_RESERVATION_MAINTENANCE)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_RESERVATION_BLOCK)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_RESERVATION_FLIGHT)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_RESERVATION_GROUND)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_RESERVATION_MAINTENANCE)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_AIRCRAFT_PROFILES)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_ORGANIZATION_SETTINGS)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_PEOPLE_LOCATIONS)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_PEOPLE_ROLES)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_RESERVATION_BLOCK)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_RESERVATION_FLIGHT)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_RESERVATION_GROUND)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_RESERVATION_MAINTENANCE)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.SIGN_RESERVATION_IN)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.SIGN_RESERVATION_OUT)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_AIRCRAFT_LIST)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_AIRCRAFT_PROFILES)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_ORGANIZATION_SETTINGS)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_PEOPLE_LIST)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_PEOPLE_PROFILES)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.APPROVE_FLIGHT_LEG)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_SAFETY_FEEDBACK)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_ORGANIZATION_DASHBOARD)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_SAFETY_CONTROLS)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.EDIT_SAFETY_CONTROLS)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.VIEW_SAFETY_CONTROLS)
    addPermissionToMap(permissionMap, PermissionNameEnum.enum.CREATE_FLIGHT_LEG_COMMENT)
  }

  function hasPermission(permission: PermissionName): boolean {
    return permissionMap.has(permission)
  }

  function canPerform(permission: PermissionName, scopeDependencies?: ScopeDependencies): boolean {
    const permissionConfig = permissionMap.get(permission)
    if (permissionConfig == null) {
      return false
    }

    if (permissionConfig.scopes == null) {
      return true
    }

    const scopeExecutorsNeeded = permissionConfig.scopes.map((scope) => scopeExecutors[scope])
    return scopeExecutorsNeeded.every((scopeExecutor) => scopeExecutor(user, scopeDependencies))
  }

  return {
    hasPermission,
    canPerform,
  }
}

type ScopeExecutor = (user: User, dependencies?: ScopeDependencies) => boolean
const scopeExecutors: Record<PermissionScope, ScopeExecutor> = {
  [PermissionsScopeEnum.enum.IS_ON_RESERVATION]: isAUserOnReservation,
  [PermissionsScopeEnum.enum.OTHER_USERS_ARE_STAFF_PILOTS]: otherUsersAreStaffPilots,
  [PermissionsScopeEnum.enum.OTHER_USERS_ARE_PILOTS]: otherUsersArePilots,
}

function isAUserOnReservation(user: User, dependencies?: ScopeDependencies) {
  if (dependencies?.reservation == null) {
    return false
  }

  return dependencies?.reservation.users.some(
    (rUser: ReservationUser) => "userId" in rUser && rUser.userId != null && rUser.userId === user.id,
  )
}

function otherUsersArePilots(user: User, dependencies?: ScopeDependencies) {
  if (dependencies?.reservation == null || dependencies?.userRoleMap == null) {
    return false
  }
  const otherUsers = dependencies.reservation.users.filter((rUser: ReservationUser) => rUser.userId !== user.id)
  return otherUsers.every((rUser) => {
    const userRoles = dependencies.userRoleMap?.[rUser.userId] ?? []
    return userRoles.includes(UserRole.enum.PILOT)
  })
}

function otherUsersAreStaffPilots(user: User, dependencies?: ScopeDependencies) {
  if (dependencies?.reservation == null || dependencies?.userRoleMap == null) {
    return false
  }

  const otherUsers = dependencies.reservation.users.filter((rUser: ReservationUser) => rUser.userId !== user.id)
  return otherUsers.every((rUser) => {
    const userRoles = dependencies.userRoleMap?.[rUser.userId] ?? []
    return userRoles.includes(UserRole.enum.STAFF_PILOT)
  })
}
