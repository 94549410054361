import { Box, Title } from "@mantine/core"
import { ModalOrDrawer, SafetyReportForm } from "@soar/frontend/components"
import { organizationsForUserAtom, useAircraft } from "@soar/frontend/contexts"
import { SafetyReport, SafetyReportInput } from "@soar/shared/types"
import { useAtomValue } from "jotai"

export type DisabledFields = {
  type: boolean
  flightLeg: boolean
  stageOfFlight: boolean
  date: boolean
  location: boolean
  equipment: boolean
  description: boolean
  organization: boolean
  anonymous: boolean
}

const disabledFields = {
  type: true,
  flightLeg: true,
  stageOfFlight: true,
  date: true,
  location: true,
  equipment: true,
  description: false,
  organization: true,
  anonymous: true,
}

export function EditSafetyReportModal({
  safetyReport,
  opened,
  onClose = () => {},
  handleSubmit = () => {},
}: {
  safetyReport: SafetyReport
  opened: boolean
  onClose?: () => void
  handleSubmit?: (safetyReportInput: SafetyReportInput) => void
}) {
  const { aircraftOptions } = useAircraft()
  const organizations = useAtomValue(organizationsForUserAtom)
  return (
    <ModalOrDrawer
      opened={opened}
      onClose={onClose}
      title={<Title order={3}>Edit safety report</Title>}
      modalProps={{
        size: "lg",
        closeOnClickOutside: false,
        closeOnEscape: false,
      }}
      drawerProps={{
        closeOnClickOutside: false,
        closeOnEscape: false,
      }}
    >
      <Box>
        <SafetyReportForm
          safetyReportInput={safetyReport}
          onSubmit={handleSubmit}
          disabledFields={disabledFields}
          onClose={onClose}
          aircraftOptions={aircraftOptions}
          organizations={organizations}
          submitButtonText="Update"
        />
      </Box>
    </ModalOrDrawer>
  )
}
