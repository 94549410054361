import { ActionIcon, Box, Menu } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { SafetyReport, SafetyReportInput } from "@soar/shared/types"
import { IconDotsVertical } from "@tabler/icons-react"
import { EditSafetyReportModal } from "../../../../../../apps/soar-ops-frontend/components/safety/editSafetyReportModal"

export function SafetyReportActionMenu({
  safetyReport,
  handleSubmit = () => {},
}: {
  safetyReport: SafetyReport
  handleSubmit?: (safetyReportInput: SafetyReportInput) => void
}) {
  const [editSafetyReportModalState, editSafetyReportModalHandler] = useDisclosure(false)
  return (
    <Box ml="xs">
      <Menu
        shadow="lg"
        offset={{
          mainAxis: 8,
          crossAxis: -10,
        }}
      >
        <Menu.Target>
          <ActionIcon variant="outline" radius="md" color="gray.4" size={25}>
            <IconDotsVertical width="16" height="16" color="#3e3e3e" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={editSafetyReportModalHandler.open}>Edit</Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <EditSafetyReportModal
        safetyReport={safetyReport}
        opened={editSafetyReportModalState}
        onClose={editSafetyReportModalHandler.close}
        handleSubmit={handleSubmit}
      />
    </Box>
  )
}
