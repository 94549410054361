import { Box, Button, Flex, Portal, Stack, Text, Title, createStyles } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { headerButtonPortalId } from "../header"
import { ModalOrDrawer } from "../modalOrDrawer"

const useStyles = createStyles({
  fontSize: {
    fontSize: "20px !important",
  },
})

export function HelpButton() {
  const { classes, cx } = useStyles()
  const [modalState, modalHandlers] = useDisclosure(false)

  return (
    <>
      <Box>
        <Button variant="subtle" onClick={modalHandlers.open} px={0}>
          <Flex align="center" gap="xs">
            <Box mt="5px">
              <Text span className={cx("material-symbols-outlined", classes.fontSize)}>
                chat
              </Text>
            </Box>
            <Text>Help</Text>
          </Flex>
        </Button>
      </Box>
      <ModalOrDrawer opened={modalState} onClose={modalHandlers.close}>
        <Box>
          <Title order={3} mb="xl">
            Need help?
          </Title>
          <Stack>
            <Text>
              NATA Compliance Services is here to help you with any questions you may have. Reach out to us via phone or email and we'll get
              back to you as soon as we can.
            </Text>
            <Text>
              <Text span fw={500}>
                E-mail support:{" "}
              </Text>
              <a href="mailto:support@natacs.aero">support@natacs.aero</a>
              <br />

              <Text span fw={500}>
                Phone support:{" "}
              </Text>
              <a href="tel:+17038425317">(703) 842-5317</a>
            </Text>
            <Text>
              Our hours of operation are from{" "}
              <Text span fw={500}>
                Monday - Friday 8:00am – 10:00pm Eastern Time
              </Text>
            </Text>
          </Stack>
        </Box>
      </ModalOrDrawer>
    </>
  )
}

export function HelpButtonInHeader() {
  const portalRoot = typeof window !== "undefined" ? document.getElementById(headerButtonPortalId) : null

  if (portalRoot != null) {
    return (
      <Portal target={portalRoot}>
        <HelpButton />
      </Portal>
    )
  } else {
    return <></>
  }
}
