import { Affix, Box, Button, Flex, useMantineTheme } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { BUTTON_GRADIENT_COLORS } from "@soar/frontend/components"
import { SafetyAssessmentRecordStatusEnum, SafetyBriefMode } from "@soar/shared/types"
import { useMitigations } from "./mitigationsAtom"

export function SafetyAssessmentButton({
  onClick,
}: {
  onClick: () => void
}) {
  const { mitigations, numThreats, isFinishedMitigating, briefStatus } = useMitigations()
  if (briefStatus?.status === SafetyAssessmentRecordStatusEnum.enum.finished) {
    return <></>
  }
  return (
    <Button
      variant={isFinishedMitigating ? "filled" : "filled"}
      radius="xl"
      color={isFinishedMitigating ? "brandBlue.6" : "black"}
      disabled={!isFinishedMitigating}
      styles={(theme) => ({
        root: {
          "&[data-disabled]": {
            // backgroundColor: theme.colors.gray[7],
            // color: theme.white,
          },
        },
      })}
      onClick={onClick}
    >
      Submit assessment
      {/*isFinishedMitigating ? (
        <> Submit assessment </>
      ) : (
        <>
          {" "}
          {mitigations.length} / {numThreats} mitigated{" "}
        </>
      )*/}
    </Button>
  )
}

function MapButton({
  onClick,
}: {
  onClick: () => void
}) {
  return (
    <Button variant={"filled"} radius="xl" color="brandBlue" styles={(theme) => ({})} onClick={onClick}>
      View Map
    </Button>
  )
}

export function SafetyFloatingButton({
  safetyBriefMode,
  onMitigationsSave = () => {},
  onMapClick = () => {},
}: {
  safetyBriefMode: SafetyBriefMode
  onMapClick: () => void
  onMitigationsSave: () => void
}) {
  return (
    <Box
      sx={(theme) => ({
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "20px",
        zIndex: 200,
        [`@media (max-width: ${theme.breakpoints.sm} )`]: {
          bottom: "16px",
        },
        backgroundColor: "transparent",
      })}
    >
      <Flex w="100%" justify="center">
        {safetyBriefMode === "assessment" ? <SafetyAssessmentButton onClick={onMitigationsSave} /> : <></>}
      </Flex>
    </Box>
  )
}
