import { Box, Button, Checkbox, Chip, Flex, Select, SimpleGrid, Stack, Text, Textarea } from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { useForm, zodResolver } from "@mantine/form"
import { DepartureDestinationDisplay, FlightLegSelect, useCustomChipOutlinedStyles } from "@soar/frontend/components"
import {
  FlightStage,
  Organization,
  SafetyReport,
  SafetyReportInput,
  SafetyReportInputSchema,
  SafetyReportSchema,
  SafetyReportType,
  SafetyReportTypeEnum,
  SchedulingFlightLegSearchDocument,
} from "@soar/shared/types"
import { FlightStageConfig, SafetyReportTypeConfig } from "@soar/shared/utils"
import { AirportInput } from "apps/soar-ops-frontend/components/airports/airportInput"
import { DisabledFields } from "apps/soar-ops-frontend/components/safety/editSafetyReportModal"
import { useEffect, useState } from "react"

const stageOfFlightOptions = Object.keys(FlightStageConfig).map((key) => {
  const config = FlightStageConfig[key]
  return {
    label: config.label,
    value: key,
  }
})

function getRelevantStageOfFlightAirports(flightStage: FlightStage | null | undefined, type: SafetyReportType) {
  if (type === "ground") {
    return {
      departure: true,
      destination: false,
    }
  }
  if (flightStage == null) {
    return {
      departure: true,
      destination: false,
    }
  }
  return FlightStageConfig[flightStage].appliesTo
}

const OptionalText = (
  <Text span fs="italic" fz="xs" c="dimmed">
    optional
  </Text>
)

export function SafetyReportForm({
  onSubmit = () => {},
  onClose = () => {},
  organizations,
  aircraftOptions,
  isSubmitting,
  disabledFields,
  safetyReportInput,
  submitButtonText,
}: {
  isSubmitting?: boolean
  onSubmit?: (safetyReport: SafetyReportInput) => void
  onClose?: () => void
  organizations?: Organization[]
  aircraftOptions: Array<{ label: string; value: string; tailNumber: string; organizationId: string | undefined | null }>
  disabledFields?: DisabledFields
  safetyReportInput?: SafetyReport
  submitButtonText: string
}) {
  const form = useForm<SafetyReportInput>({
    initialValues: {
      type: SafetyReportTypeEnum.enum.flight,
      title: "",
      details: "",
      description: safetyReportInput?.description,
      organizationId: "",
      airport: [],
      dateOfIncidence: new Date(),
      isAnonymous: false,
    },
    validate: zodResolver(SafetyReportInputSchema),
  })
  const [flightLeg, setFlightLeg] = useState<SchedulingFlightLegSearchDocument>()
  const [departureAirport, setDepartureAirport] = useState("")
  const [destinationAirport, setDestinationAirport] = useState("")
  const { cx, classes } = useCustomChipOutlinedStyles({ borderStyle: "solid" })

  useEffect(() => {
    if (organizations != null) {
      form.setValues({
        organizationId: organizations[0].id,
      })
    }
  }, [organizations])

  useEffect(() => {
    if (safetyReportInput != null) {
      setDepartureAirport(safetyReportInput.airport?.at(0)!)
      setDestinationAirport(safetyReportInput.airport?.at(1)!)
      form.setValues({
        aircraftId: safetyReportInput.aircraftId,
        organizationId: safetyReportInput.organizationId,
        stageOfFlight: safetyReportInput.stageOfFlight,
        details: safetyReportInput.description ?? safetyReportInput.details,
      })
      form.setFieldValue("flightLegId", safetyReportInput.flightLegId)
    }
  }, [safetyReportInput])

  const stageOfFlightAirportRelevance = getRelevantStageOfFlightAirports(form.values.stageOfFlight, form.values.type)

  useEffect(() => {
    const airports: string[] = []
    const stageOfFlightAirportRelevance = getRelevantStageOfFlightAirports(form.values.stageOfFlight, form.values.type)
    if (departureAirport && stageOfFlightAirportRelevance.departure && departureAirport.length > 0) {
      airports.push(departureAirport)
    }
    if (destinationAirport && stageOfFlightAirportRelevance.destination && destinationAirport.length > 0) {
      airports.push(destinationAirport)
    }
    form.setFieldValue("airport", airports)
  }, [departureAirport, destinationAirport, form.values.stageOfFlight])

  const onFlightLegSelect = (flightLeg: SchedulingFlightLegSearchDocument) => {
    form.setFieldValue("organizationId", flightLeg.organizationId ?? "")
    form.setFieldValue("flightLegId", flightLeg.id)
    form.setFieldValue("dateOfIncidence", new Date(flightLeg.departAt))

    setDepartureAirport(flightLeg.departureAirport)
    setDestinationAirport(flightLeg.destinationAirport)

    const matchedAircraft = aircraftOptions?.find(
      (option) => option.tailNumber === flightLeg.aircraftTailNumber && option.organizationId === flightLeg.organizationId,
    )
    form.setFieldValue("aircraftId", matchedAircraft?.value)
    setFlightLeg(flightLeg)
  }
  const onRemoveFlightLegClick = () => {
    form.setFieldValue("organizationId", "")
    form.setFieldValue("flightLegId", undefined)
    form.setFieldValue("dateOfIncidence", null)
    form.setFieldValue("aircraftId", undefined)
    form.setFieldValue("airport", [])
    setDepartureAirport("")
    setDestinationAirport("")
    setFlightLeg(undefined)
  }

  return (
    <Box
      component="form"
      onSubmit={form.onSubmit(async (values) => {
        onSubmit(values)
        onClose()
      })}
    >
      <Stack>
        <Chip.Group {...form.getInputProps("type")}>
          <SimpleGrid cols={2} style={{ order: 1 }}>
            <Chip value={SafetyReportTypeEnum.enum.flight} classNames={classes} disabled={disabledFields?.type}>
              {SafetyReportTypeConfig[SafetyReportTypeEnum.enum.flight].label}
            </Chip>
            <Chip value={SafetyReportTypeEnum.enum.ground} classNames={classes} disabled={disabledFields?.type}>
              {SafetyReportTypeConfig[SafetyReportTypeEnum.enum.ground].label}
            </Chip>
          </SimpleGrid>
        </Chip.Group>
        <Stack
          style={{
            order: form.values.type === "ground" ? 15 : 2,
          }}
        >
          {flightLeg == null ? (
            <FlightLegSelect
              onSelect={onFlightLegSelect}
              textInputProps={{
                label: <Text>Flight leg {OptionalText}</Text>,
                placeholder: "Search for the flight",
              }}
              disabled={disabledFields?.flightLeg}
            />
          ) : (
            <Box pt={2}>
              <Text fz="sm" fw={500}>
                Flight leg {OptionalText}
              </Text>
              <Flex justify="space-between" align="flex-start" mt={10}>
                <DepartureDestinationDisplay from={flightLeg.departureAirport} to={flightLeg.destinationAirport} size="xs" />

                <Button variant="subtle" onClick={onRemoveFlightLegClick} px={0} mt={-10} disabled={disabledFields?.flightLeg}>
                  Change flight
                </Button>
              </Flex>
            </Box>
          )}
          <Select
            disabled={disabledFields?.stageOfFlight ?? form.values.type === "ground"}
            display={form.values.type === "ground" ? "none" : "block"}
            data={stageOfFlightOptions}
            label={<Text>Stage of flight {OptionalText}</Text>}
            {...form.getInputProps("stageOfFlight")}
            placeholder="Select when the issue occurred (e.g., Takeoff)"
          />
        </Stack>

        <DatePickerInput
          disabled={disabledFields?.date}
          label="Date"
          {...form.getInputProps("dateOfIncidence")}
          // @ts-ignore
          placeholder="Select the observation date"
          style={{ order: 3 }}
          valueFormat="MMM D, YYYY"
          popoverProps={{
            middlewares: {
              flip: false,
              shift: false,
              inline: false,
            },
          }}
          styles={(theme) => ({
            day: {
              "&[data-weekend]": {
                color: "brandBlue.5",
                "&[data-selected]": {
                  color: "#FFF",
                },
              },
            },
          })}
        />
        {stageOfFlightAirportRelevance.departure && (
          <Box style={{ order: 4 }}>
            <Text fz="sm" fw={500}>
              {form.values.type === "flight" && form.values.stageOfFlight === "cruise" ? "Departure location" : "Location"}
            </Text>
            <AirportInput
              textInputProps={{
                error: form.getInputProps("airport").error,
              }}
              onSelect={(airport) => {
                setDepartureAirport(airport?.icao_id ?? airport?.faa_airport_id ?? "")
              }}
              placeholder="Search for the airport"
              value={departureAirport}
              disabledEditFields={disabledFields?.location}
            />
          </Box>
        )}
        {stageOfFlightAirportRelevance.destination && (
          <Box style={{ order: 5 }}>
            <Text fz="sm" fw={500}>
              {form.values.type === "flight" && form.values.stageOfFlight === "cruise" ? "Arrival location" : "Location"}
            </Text>
            <AirportInput
              placeholder="Search for the airport"
              textInputProps={{
                error: form.getInputProps("airport").error,
              }}
              onSelect={(airport) => {
                setDestinationAirport(airport?.icao_id ?? airport?.faa_airport_id ?? "")
              }}
              value={destinationAirport}
              disabledEditFields={disabledFields?.location}
            />
          </Box>
        )}

        <Select
          data={aircraftOptions}
          label={<Text>Equipment {OptionalText}</Text>}
          {...form.getInputProps("aircraftId")}
          style={{ order: 6 }}
          placeholder="Select the aircraft"
          clearable
          disabled={disabledFields?.equipment}
        />

        <Textarea
          label="Description"
          {...form.getInputProps("details")}
          autosize
          minRows={5}
          style={{ order: 20 }}
          placeholder="Describe what happened..."
          disabled={disabledFields?.description}
        />

        <Select
          label="Organization"
          data={organizations?.map((org) => ({ value: org.id, label: org.name })) ?? []}
          {...form.getInputProps("organizationId")}
          style={{ order: 30 }}
          disabled={disabledFields?.organization}
        />

        <Checkbox label="Anonymous?" {...form.getInputProps("isAnonymous")} style={{ order: 40 }} disabled={disabledFields?.anonymous} />

        <Button variant="filled" type="submit" style={{ order: 50 }} loading={isSubmitting}>
          {submitButtonText}
        </Button>
      </Stack>
    </Box>
  )
}
