import { createStyles } from "@mantine/core"

export const useCustomRichTextEditorStyles = createStyles((theme) => ({
  box: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: "5px",
  },
  customEditor: {
    fontSize: theme.fontSizes.sm,
    fontFamily: theme.fontFamily,
    border: "none",

    "& .ProseMirror": {
      outline: "none",
      fontSize: theme.fontSizes.sm,
      lineHeight: 1.6,
      paddingLeft: "10px",
      minHeight: "4em",
      width: "100%",
      wordBreak: "break-word",
      overflow: "hidden",
    },

    "& .ProseMirror p.is-editor-empty:first-child::before": {
      content: "attr(data-placeholder)",
      color: theme.colors.gray[5],
      pointerEvents: "none",
      float: "left",
    },

    "& .ProseMirror:focus": {
      border: "none",
      outline: "none",
      boxShadow: "none",
    },

    "& .mention": {
      color: theme.colors["brandBlue"][8],
      padding: "0.1rem 0.3rem",
    },
  },

  dropdownMenu: {
    fontSize: theme.fontSizes.sm,
    background: "white",
    border: "none",
    display: "flex",
    flexDirection: "column",
    gap: "0.1rem",
    overflow: "auto",
    padding: "0.4rem",
    position: "relative",

    "& button": {
      backgroundColor: "white",
      display: "flex",
      alignItems: "center",
      gap: "0.25rem",
      textAlign: "left",
      width: "100%",
      border: "none",
      padding: "0.4rem",
      cursor: "pointer",
    },

    "& button:hover, & button:hover.is-selected": {
      backgroundColor: theme.colors["brandBlue"][7],
    },

    "& button.is-selected": {
      backgroundColor: theme.colors["brandBlue"][7],
    },
  },
}))
