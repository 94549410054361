import { Box, Button, Chip, Flex, ScrollArea, SimpleGrid, Stack, Text } from "@mantine/core"
import { SafetyReportFactorType, SafetyReportFactorTypeEnum } from "@soar/shared/types"
import { SafetyReportFactorConfig } from "@soar/shared/utils"
import { useEffect, useState } from "react"
import { useCustomChipOutlinedStyles } from "../customChip"
import { BUTTON_GRADIENT_COLORS } from "../tokens"
import { HAZARD_CATEGORY_BADGE_PROPS } from "./constants"

function groupHazardCategoryByType(safetyCategories: SafetyReportFactorType[]) {
  return safetyCategories.reduce(
    (memo, key) => {
      const config = SafetyReportFactorConfig[key]
      const isGroundCategory = config.categories.includes("ground")
      const isFlightCategory = config.categories.includes("flight")
      const category = key as SafetyReportFactorType

      if (isGroundCategory && isFlightCategory) {
        return {
          flightCategories: [...memo.flightCategories, category],
          groundCategories: [...memo.groundCategories, category],
        }
      } else if (isGroundCategory) {
        return {
          flightCategories: memo.flightCategories,
          groundCategories: [...memo.groundCategories, category],
        }
      } else if (isFlightCategory) {
        return {
          flightCategories: [...memo.flightCategories, category],
          groundCategories: memo.groundCategories,
        }
      }
      return memo
    },
    { flightCategories: [] as SafetyReportFactorType[], groundCategories: [] as SafetyReportFactorType[] },
  )
}

const { flightCategories, groundCategories } = groupHazardCategoryByType(SafetyReportFactorTypeEnum.options)

export function HazardCategoryForm({
  value,
  onSave = () => {},
}: {
  value?: SafetyReportFactorType[]
  onSave?: (hazardCatgories: SafetyReportFactorType[]) => void
}) {
  const [selectedFlightCategories, setSelectedFlightCategories] = useState<SafetyReportFactorType[]>([])
  const [selectedGroundCategories, setSelectedGroundCategories] = useState<SafetyReportFactorType[]>([])
  const { cx, classes } = useCustomChipOutlinedStyles({})

  useEffect(() => {
    if (value != null) {
      const incoming = groupHazardCategoryByType(value)
      setSelectedGroundCategories(incoming.groundCategories)
      setSelectedFlightCategories(incoming.flightCategories)
    }
  }, [value])

  const handleSave = () => {
    const set = new Set<SafetyReportFactorType>([...selectedFlightCategories, ...selectedGroundCategories])
    onSave([...set.values()])
  }

  return (
    <Box
      style={{
        height: "100%",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr auto",
      }}
    >
      <Box
        style={{
          overflowY: "scroll",
        }}
        pb="lg"
      >
        <Stack spacing="lg">
          <Box>
            <Text fw={600} mb="sm">
              Flight
            </Text>
            <Chip.Group
              value={selectedFlightCategories}
              multiple
              onChange={(value) => {
                setSelectedFlightCategories(value as SafetyReportFactorType[])
              }}
            >
              <SimpleGrid breakpoints={[{ minWidth: "md", cols: 4 }]} cols={2}>
                {flightCategories.map((category) => {
                  const config = SafetyReportFactorConfig[category]
                  return (
                    <Chip value={category} classNames={classes}>
                      {config.label}
                    </Chip>
                  )
                })}
              </SimpleGrid>
            </Chip.Group>
          </Box>
          <Box>
            <Text fw={600} mb="sm">
              Ground
            </Text>
            <Chip.Group
              value={selectedGroundCategories}
              multiple
              onChange={(value) => {
                setSelectedGroundCategories(value as SafetyReportFactorType[])
              }}
            >
              <SimpleGrid breakpoints={[{ minWidth: "md", cols: 4 }]} cols={2}>
                {groundCategories.map((category) => {
                  const config = SafetyReportFactorConfig[category]
                  return (
                    <Chip value={category} classNames={classes}>
                      {config.label}
                    </Chip>
                  )
                })}
              </SimpleGrid>
            </Chip.Group>
          </Box>
        </Stack>
      </Box>
      <Box mt="sm">
        <Button onClick={handleSave} w="100%" variant="filled">
          Save
        </Button>
      </Box>
    </Box>
  )
}
