import { Badge, Box, Button, Flex, Paper, Stack, Text, useMantineTheme } from "@mantine/core"
import type { AxisTickProps } from "@nivo/axes"
import { DashboardTimespan, DashboardTimespanEnum, FlightLegStatusEnum, SchedulingFlightLeg } from "@soar/shared/types"
import dayjs, { Dayjs } from "dayjs"
import IsBetween from "dayjs/plugin/isBetween"
import dynamic from "next/dynamic"
import { useCallback, useMemo } from "react"
import { CustomLink } from "../customLink"
import { SectionHeader } from "./SectionHeader"
import { calculateBreakpoints } from "./graphHelper"

const ResponsiveLine = dynamic(() => import("@nivo/line").then((mod) => mod.ResponsiveLine), {
  loading: () => <p>Loading...</p>,
  ssr: false,
})

dayjs.extend(IsBetween)

type GraphDataRollup = {
  x: string | number | Date
  y: number
}

function calculateGraphData(data: SchedulingFlightLeg[], option: DashboardTimespan, dateStart: Dayjs) {
  const { breakpoints, tickValues, xFormat } = calculateBreakpoints(option, dateStart)
  console.log("breakpoints: ", breakpoints)

  let totalComplete = 0
  let totalIncomplete = 0
  const now = dayjs()

  const graphData = breakpoints.reduce((memo, breakpoint, index) => {
    let nextBreakpoint = breakpoints[index + 1]
    if (nextBreakpoint == null) {
      return memo
    }
    if (option === DashboardTimespanEnum.enum.week_4 && index === 3) {
      nextBreakpoint = nextBreakpoint.add(1, "day")
    }

    let complete = 0
    let incomplete = 0
    const flightLegs = data.filter((flightLeg) => {
      const day = dayjs(flightLeg.departAt)
      const isInBetweenBreakpoints = day.isBetween(breakpoint, nextBreakpoint, "second", "[)")
      return isInBetweenBreakpoints
    })
    const total = flightLegs.length
    for (const flightLeg of flightLegs) {
      if (flightLeg.status === FlightLegStatusEnum.enum.created || flightLeg.status === FlightLegStatusEnum.enum.awaiting_approval) {
        incomplete = incomplete + 1
        totalIncomplete = totalIncomplete + 1
      } else {
        complete = complete + 1
        totalComplete = totalComplete + 1
      }
    }
    return [
      ...memo,
      {
        label: breakpoint.format(xFormat),
        complete,
        incomplete,
        total,
        day: breakpoint,
      },
    ]
  }, [] as { label: string; day: Dayjs; complete: number; incomplete: number; total: number }[])

  const graphDataCompleteRollup: GraphDataRollup[] = []
  const graphDataIncompleteRollup: GraphDataRollup[] = []
  let maxValue = 0

  for (const datum of graphData) {
    graphDataCompleteRollup.push({
      x: datum.label,
      y: datum.complete,
    })
    graphDataIncompleteRollup.push({
      x: datum.label,
      y: datum.incomplete,
    })

    if (datum.complete > maxValue) {
      maxValue = datum.complete
    }

    if (datum.incomplete > maxValue) {
      maxValue = datum.incomplete
    }
  }
  const nextMaxValue = maxValue - (maxValue / 10 - Math.floor(maxValue / 10)) * 10 + 10

  return {
    breakpoints,
    graphData,
    graphDataCompleteRollup,
    graphDataIncompleteRollup,
    maxValue: nextMaxValue,
    tickValues,
    totalComplete,
    totalIncomplete,
  }
}

const PercentageFormatter = new Intl.NumberFormat("en-US", { style: "percent" })
function CustomTick({
  tick,
  shouldRender = false,
}: {
  tick: AxisTickProps<string>
  shouldRender?: boolean
}) {
  return (
    <g transform={`translate(${tick.x},${tick.y + 22})`}>
      <rect x={-14} y={-6} rx={3} ry={3} width={28} height={24} fill="transparent" />
      <rect x={-12} y={-12} rx={2} ry={2} width={24} height={24} fill="transparent" />
      <line stroke="rgb(220,220,220)" strokeWidth={1} y1={-22} y2={-12} />
      {shouldRender && (
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fontSize: 10,
          }}
        >
          {tick.value}
        </text>
      )}
    </g>
  )
}

export function AssessmentsCompletedSection({
  data = [],
  startDate,
  dateKey,
  organizationId = "",
  timespanLabel,
  viewMoreUrl,
}: {
  dateKey: DashboardTimespan
  startDate: Dayjs
  data?: SchedulingFlightLeg[]
  timespanLabel: string
  organizationId?: string
  viewMoreUrl: string
}) {
  const theme = useMantineTheme()
  const { graphDataCompleteRollup, graphDataIncompleteRollup, breakpoints, maxValue, tickValues, totalComplete, total, percentage } =
    useMemo(() => {
      const graphData = calculateGraphData(data, dateKey, startDate)
      const total = graphData.totalComplete + graphData.totalIncomplete
      const percentage = total > 0 ? graphData.totalComplete / total : 0

      return {
        total,
        percentage,
        ...graphData,
      }
    }, [data, dateKey])

  return (
    <Box w="100%">
      <SectionHeader title="Assessments completed" timePeriod={timespanLabel} />
      <Flex align="center" gap="xs">
        <Text fz={26}>
          <Text span c="gray.7">
            {totalComplete}{" "}
          </Text>
          <Text span c="gray.7">
            / {total}
          </Text>
        </Text>
        <Badge size="xl" variant="filled" color="spotlight.7" sx={{ color: "#00000066" }}>
          {PercentageFormatter.format(percentage)}
        </Badge>
      </Flex>
      <Box h={275} w="100%">
        <ResponsiveLine
          data={[
            {
              id: `complete-${dateKey}`,
              data: graphDataCompleteRollup,
              color: theme.colors["brandBlue"][7],
            },
            {
              id: `incomplete-${dateKey}`,
              data: graphDataIncompleteRollup,
              color: theme.colors.gray[7],
            },
          ]}
          colors={[theme.colors["brandBlue"][7], theme.colors.gray[7]]}
          xScale={{
            type: "point",
          }}
          yScale={{
            type: "linear",
            min: 0,
            max: maxValue,
          }}
          axisBottom={{
            tickValues: tickValues,
          }}
          axisLeft={{
            tickValues: [0, maxValue],
          }}
          enableGridY={false}
          enableCrosshair
          enableTouchCrosshair
          curve="linear"
          enableSlices="x"
          sliceTooltip={(slice) => {
            const completeSeries = slice.slice.points.find((series) => series.serieId === `complete-${dateKey}`)
            const incompleteSeries = slice.slice.points.find((series) => series.serieId === `incomplete-${dateKey}`)
            const series = [
              {
                id: "complete",
                label: "Complete",
                value: (completeSeries?.data.y as number) ?? 0,
                valueFormatted: completeSeries?.data.yFormatted ?? 0,
                color: completeSeries?.serieColor,
              },
              {
                id: "incomplete",
                label: "Incomplete",
                value: (incompleteSeries?.data.y as number) ?? 0,
                valueFormatted: incompleteSeries?.data.yFormatted ?? 0,
                color: incompleteSeries?.serieColor,
              },
            ].sort((a, b) => b.value - a.value)
            return (
              <Paper radius="sm" shadow="xs" p="sm">
                <Text ta="center" fw={700} fz="lg" mb="sm">
                  {completeSeries?.data.xFormatted}
                </Text>
                <Stack spacing={5}>
                  {series.map((serie) => (
                    <Text>
                      <Text fw={700} span c={serie.color}>
                        {serie.label}:{" "}
                      </Text>
                      {serie.valueFormatted}
                    </Text>
                  ))}
                </Stack>
              </Paper>
            )
          }}
          margin={{
            top: 30,
            right: 16,
            bottom: 30,
            left: 30,
          }}
        />
      </Box>
      <Flex justify="flex-end" pt="xs">
        <CustomLink href={viewMoreUrl} passHref legacyBehavior>
          <Button component="a" variant="subtle" px={0}>
            View more
          </Button>
        </CustomLink>
      </Flex>
    </Box>
  )
}
