import { z } from "zod"
import { FileDetailsSchema } from "./File"
import { LocationSchema } from "./Location"

export const EngineTypes = z.enum(["Electric", "Jet", "Piston", "Rocket", "Turboshaft", "Turboprop"])
export type EngineTypeEnum = z.infer<typeof EngineTypes>

export const EngineCounts = z.enum(["1", "2", "3", "4", "6", "8"])
export type EngineCountsEnum = z.infer<typeof EngineCounts>

export const PlaneTypes = z.enum(["Land Plane", "Sea Plane", "Helicopter", "Gyrocopter", "Amphibian", "Tiltrotor"])
export type PlaneTypeEnum = z.infer<typeof PlaneTypes>

export const AircraftStatus = z.enum(["Active", "Inactive"])
export type AircraftStatusEnum = z.infer<typeof AircraftStatus>

export const MaintenanceMilestonesSchema = z.object({
  totalOverhaul: z.number().min(0).nullish(),
  "50hour": z.number().min(0).nullish(),
  "100hour": z.number().min(0).nullish(),
  annual: z.coerce.date().nullish(),
})

export const AircraftSchema = z.object({
  id: z.string(),
  tailNumber: z.string().min(1),
  locationId: z.string().nullish(),
  manufacturer: z.string().min(1).nullish(),
  model: z.string().min(1).nullish(),
  icaoCode: z.string().min(1).nullish(),
  engineType: EngineTypes.nullish(),
  numEngines: z
    .preprocess((val) => {
      if (typeof val === "number" && !isNaN(val)) {
        return String(val)
      } else if (typeof val === "string") {
        return val
      } else {
        return undefined
      }
    }, EngineCounts)
    .nullish(),
  currentHobbs: z.number().nonnegative().optional(),
  currentTach: z.number().nonnegative().optional(),
  currentTach2: z.number().nonnegative().optional(),
  avatarUrl: z.string().nullish(),
  airspeed: z.number().nonnegative().nullish(),
  fuelBurn: z.number().nonnegative().nullish(),

  minRunwayLengthFt: z.number().nonnegative().nullish(),
  serialNumber: z.string().nullish(),

  maxWindsThreshold: z.number().nonnegative().nullish(),
  maxWeight: z.number().nonnegative().nullish(),
  maintenanceMilestones: MaintenanceMilestonesSchema.default({}),
  status: AircraftStatus.nullish(),
})

export const AircraftWithLocationSchema = AircraftSchema.extend({
  location: LocationSchema,
})

export const AircraftDetailsSchema = AircraftSchema.omit({ id: true })

export const AircraftDetailsWithAvatarSchema = AircraftDetailsSchema.extend({
  avatar: FileDetailsSchema,
})

export const AircraftDetailsDBSchema = AircraftDetailsSchema.extend({
  numEngines: z.coerce.number().nullish(),
  maintenanceMilestones: MaintenanceMilestonesSchema.extend({
    annual: z.coerce.string().nullish(),
  }).default({}),
})

export const AircraftPerfDetailsSchema = AircraftSchema.pick({
  tailNumber: true,
  airspeed: true,
  fuelBurn: true,
  maxWindsThreshold: true,
  maxWeight: true,
  minRunwayLengthFt: true,
})
  .required()
  .extend({
    serialNumber: AircraftSchema.shape.serialNumber,
    locationId: AircraftSchema.shape.locationId,
  })

export type AircraftPerfDetails = z.infer<typeof AircraftPerfDetailsSchema>

export type Aircraft = z.infer<typeof AircraftSchema>
export type AircraftWithLocation = z.infer<typeof AircraftWithLocationSchema>
export type AircraftDetails = z.infer<typeof AircraftDetailsSchema>
export type AircraftDetailsWithAvatar = z.infer<typeof AircraftDetailsWithAvatarSchema>

export const AircraftWithOrgDetailsSchema = AircraftSchema.extend({
  organizationId: z.string().nullish(),
  organizationShortName: z.string().nullish(),
  organizationName: z.string().nullish(),
})
export type AircraftWithOrgDetails = z.infer<typeof AircraftWithOrgDetailsSchema>
