import { ActionIcon, Menu } from "@mantine/core"
import { IconDotsVertical } from "@tabler/icons-react"

export function AircraftActionMenu({
  onDisableAircraftClick = () => {},
  canDisableAircraft = false,
  isDisabled = false,
}: {
  onDisableAircraftClick?: (isDisabled: boolean) => void
  canDisableAircraft?: boolean
  isDisabled?: boolean
}) {
  if (!canDisableAircraft) {
    // user can't do nuthin, don't need no menu
    return <></>
  }
  return (
    <Menu
      shadow="lg"
      offset={{
        mainAxis: 8,
        crossAxis: -10,
      }}
    >
      <Menu.Target>
        <ActionIcon
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          <IconDotsVertical />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {canDisableAircraft && (
          <Menu.Item
            onClick={(event) => {
              event.stopPropagation()
              onDisableAircraftClick(isDisabled)
            }}
          >
            {isDisabled ? "Reactivate aircraft" : "Deactivate aircraft"}
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  )
}
