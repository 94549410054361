import { Button, ButtonProps } from "@mantine/core"
import { IconPlus } from "@tabler/icons-react"
import { ReactNode, Ref, forwardRef } from "react"
import { BUTTON_GRADIENT_COLORS } from "../tokens"

type Props = {
  onClick?: () => void
  buttonProps?: ButtonProps
}

export function HeaderActionButtonBase({ buttonProps = {}, onClick = () => {} }: Props, ref: Ref<HTMLButtonElement>) {
  return (
    <Button
      ref={ref}
      p={2}
      style={{
        borderRadius: "100%",
      }}
      variant="filled"
      h={24}
      w={24}
      mt={2}
      {...buttonProps}
      onClick={onClick}
    >
      <IconPlus width={16} height={16} />
    </Button>
  )
}

export const HeaderActionButton = forwardRef<HTMLButtonElement, Props>(HeaderActionButtonBase)
