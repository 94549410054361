import { Box, TextInputProps, createStyles } from "@mantine/core"
import { organizationsForUserAtom } from "@soar/frontend/contexts"
import { SchedulingFlightLegSearchDocument } from "@soar/shared/types"
import { getFlightLegSearchResultsTypesense } from "@soar/shared/utils"
import { useAtomValue } from "jotai"
import { useCallback } from "react"
import { SearchSelect } from "../searchSelect"
import { FlightLegResultCard } from "./resultCard"

async function onQueryFlightLegs(query: string, key: string, organizations: string[]) {
  const response = await getFlightLegSearchResultsTypesense(query, key, {
    filterBy: organizations.length > 0 ? `organizationId:=[${organizations.join(", ")}]` : undefined,
  })
  return response.results
}

function FlightLegResult({
  data,
}: {
  data: SchedulingFlightLegSearchDocument
}) {
  return (
    <Box pl={8} pr={12} py={8}>
      <FlightLegResultCard flightLeg={data} />
    </Box>
  )
}

export function FlightLegSelect({
  onSelect = () => {},
  textInputProps,
  disabled = false,
}: {
  onSelect?: (flightLeg: SchedulingFlightLegSearchDocument | undefined) => void
  textInputProps?: Partial<TextInputProps>
  disabled?: boolean
}) {
  const organizations = useAtomValue(organizationsForUserAtom)

  const onQuery = useCallback((query: string, key: string) => {
    return onQueryFlightLegs(query, key, organizations?.map((org) => org.id) ?? [])
  }, [])
  return (
    <SearchSelect
      cacheKey="flightLegs"
      onQuery={onQuery}
      renderResult={FlightLegResult}
      onSelect={onSelect}
      textInputProps={textInputProps}
      selectOnBlur={false}
      disabled={disabled}
    />
  )
}
