import { Box, Flex, Paper, Stack, Text, UnstyledButton } from "@mantine/core"
import { SafetyReport, SafetyReportSearchDocument, SafetyReportType } from "@soar/shared/types"
import { SAFETY_REPORT_IDENTIFIER_PREFIX, SafetyReportTypeConfig, formatDate } from "@soar/shared/utils"
import dayjs from "dayjs"
import { Ref, forwardRef } from "react"
import { RiskAssessmentLevelCircle } from "../riskAssessment"
import { SafetyReportStatusDisplay } from "./SafetyReportStatus"

export function SafetyReportCardBase({
  safetyReport,
}: {
  safetyReport: SafetyReport | SafetyReportSearchDocument
}) {
  return (
    <UnstyledButton w="100%">
      <Paper py="sm" px="lg" radius={0}>
        <Flex justify="space-between" align="stretch">
          <Stack c="dimmed" fw={600} spacing={2}>
            <Text fw={700} fz="lg" color="black">
              {safetyReport.title}
            </Text>
            {safetyReport.type != null && safetyReport.type.length > 0 && safetyReport.type in SafetyReportTypeConfig && (
              <Text>{SafetyReportTypeConfig[safetyReport.type as SafetyReportType].label}</Text>
            )}
            <Text>
              {safetyReport.createdBy == null ? "Anonymous" : `${safetyReport.createdBy.firstName} ${safetyReport.createdBy.lastName}`}
            </Text>
            <Text>{formatDate(dayjs(safetyReport.createdAt), { date: { format: "long" }, time: true, includeTimezone: true })}</Text>
          </Stack>
          <Flex fw={700} direction="column" justify="space-between" align="flex-end" gap={6} mt={3}>
            <SafetyReportStatusDisplay status={safetyReport.status} justify="flex-end" size="sm" />
            <RiskAssessmentLevelCircle
              severity={safetyReport.riskAssessmentSeverity}
              likelihood={safetyReport.riskAssessmentLikelihood}
              size={20}
            />
            <Box />
          </Flex>
        </Flex>
      </Paper>
    </UnstyledButton>
  )
}

export const SafetyReportCard = SafetyReportCardBase
