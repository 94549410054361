import { Box, Title } from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import { ModalOrDrawer, SafetyReportForm } from "@soar/frontend/components"
import { organizationsForUserAtom, useAircraft } from "@soar/frontend/contexts"
import { trpc } from "@soar/frontend/trpc-client"
import { SafetyReport, SafetyReportInput, SafetyReportSchema } from "@soar/shared/types"
import { useAtomValue } from "jotai"

export function SafetyReportModal({
  opened,
  onClose = () => {},
  onSuccess = () => {},
}: {
  opened: boolean
  onClose?: () => void
  onSuccess?: (post: SafetyReport) => void
}) {
  const { mutateAsync, isLoading } = trpc.safetyReport.createSafetyReport.useMutation()
  const organizations = useAtomValue(organizationsForUserAtom)
  const { aircraftOptions } = useAircraft()

  const handleSubmit = async (safetyReport: SafetyReportInput) => {
    const safetyReportResponse = await mutateAsync({
      ...safetyReport,
    })
    const safetyReportParsed = SafetyReportSchema.parse(safetyReportResponse)
    onClose()
    showNotification({
      color: "green",
      message: "Safety report has been submitted!",
    })
    onSuccess(safetyReportParsed)
  }

  return (
    <ModalOrDrawer
      opened={opened}
      onClose={onClose}
      title={<Title order={3}>Submit safety report</Title>}
      modalProps={{
        size: "lg",
        closeOnClickOutside: false,
        closeOnEscape: false,
      }}
      drawerProps={{
        closeOnClickOutside: false,
        closeOnEscape: false,
      }}
    >
      <Box>
        <SafetyReportForm
          organizations={organizations ?? []}
          onSubmit={handleSubmit}
          aircraftOptions={aircraftOptions}
          isSubmitting={isLoading}
          submitButtonText="Submit"
        />
      </Box>
    </ModalOrDrawer>
  )
}
