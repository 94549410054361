import { Box, Flex, Title, TitleOrder } from "@mantine/core"
import { ThreatColors, determineThreatCategory } from "@soar/shared/utils"
import { ReactNode } from "react"
import { match } from "ts-pattern"

export function ThreatHeader({
  label,
  threatValue,
  icon,
  order = 4,
}: { label: string; threatValue: number; icon?: ReactNode; order?: TitleOrder }) {
  const threatCategory = determineThreatCategory(threatValue)
  const threatColor = threatCategory != null ? ThreatColors[threatCategory] : "#555"
  return <BaseThreatHeader order={order} icon={icon} color={threatColor} label={label} />
}

export function BaseThreatHeader({
  label,
  icon,
  order = 4,
  color,
  fz,
  iconSize = 26,
  variant = "normal",
}: {
  label: string
  icon?: ReactNode
  order?: TitleOrder
  color: string
  fz?: string | number
  iconSize?: number
  variant?: "pill" | "normal" | "outline"
}) {
  return (
    <Title
      order={order}
      tt="uppercase"
      mb="xs"
      fz={fz}
      sx={(theme) =>
        match(variant)
          .with("pill", () => ({
            backgroundColor: theme.colors["spotlight"][7],
            color: "#00000066",
            borderRadius: theme.radius.lg,
            border: "1px solid transparent",
            padding: "1.25px 12px",
          }))
          .with("outline", () => ({
            color: "#00bfe1",
            borderRadius: theme.radius.lg,
            border: "1px solid #00bfe1",
            padding: "1.25px 12px",
          }))
          .otherwise(() => ({}))
      }
    >
      <Flex align="center">
        {variant === "normal" && (
          <Box
            sx={(theme) => ({
              backgroundColor: theme.colors.grape[6],
              width: `${iconSize}px`,
              height: `${iconSize}px`,
              borderRadius: "100%",
            })}
            mr="5px"
          >
            {icon}
          </Box>
        )}
        {label}
      </Flex>
    </Title>
  )
}
